




import { Component, Vue } from 'vue-property-decorator'

const AppProps = Vue.extend({
	mounted() {
		window.top.location.href = window.origin; 
	},
})
@Component({
	components: {}
})
export default class ExitIframe extends AppProps { }
